<template>
   <!-- Container principal da tela de login -->
   <v-container 
         fluid
         class="pa-0 ma-0"
   >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo da tela de login -->
          <v-card
            class="rounded-0"
          >
            <v-img
              min-height="600px"
              max-height="920px"
              src="img/escritorio.jpg"
            >
              <!-- Dentro da imagem existe um outro container para centralizar o card que contém o formulário de login -->
              <v-container 
                    fluid
                    fill-height

              >
                <!-- Essa v-row centraliza o card do formulário na vertical --> 
                <v-row
                  justify="center"
                >
                  <!-- Para telas pequenas usa toda a largura da tela e para telas medias usa somente sete colunas --> 
                  <v-col cols="12" md="7" >

                    <!-- Esse é o card branco que aparece no meio da tela --> 
                    <v-card
                      class="mx-auto rounded-xl"
                      elevation="12"
                      min-height="200"
                    >
                      <v-card-text class="text--center"> Esta funcionalidade está temporariamente indisponível</v-card-text> 
                    </v-card>
                  </v-col>
                </v-row> 
              </v-container>   
            </v-img>
          <!--  <v-footer
              padless
            >
              <v-card
                flat
                tile
                width="100%"
                height="200px"
                class="grey darken-4 text-center"
              >

                <v-card-text class="white--text">
                  {{ new Date().getFullYear() }} — <strong>Serpros Fundo Multipatrocinado</strong>
                </v-card-text>
              </v-card>
            </v-footer> -->
          </v-card>  
        </v-col>
      </v-row>  
   </v-container>

   
</template>

<script>
  import CompData from '@/components/CompData.vue';
  export default {
    
    components: {
            CompData,
    },
    data: function(){
      return {  
          active: null,
          indeterminate: null,
          loading: false,
          valid: false,
          obrigatorio: v => !!v || 'Obrigatório',
          Rules:{
            Required:[v => !!v || 'Obrigatório!'],   
          },
          cpf: null,
          data_nascimento: "",
          nome_mae: '',
          email: '',
          email_novo: '',
          mensagem: '',
          mostra_mensagem: false,
          tipo_mensagem: 'error',
          etapa: 1,
          codigo_confirmacao: '',
          menu: false,
      }
    },

    created: function () {

    },
    computed: {
      data_formatada() {
        if(this.data_nascimento == ""){
          return "  /  /    ";
        }else{
          return this.data_nascimento.substring(8,10)+"/"+this.data_nascimento.substring(5,7)+"/"+this.data_nascimento.substring(0,4);
        }
        
      }
    },
    methods:{
      
    /*  verificaCPF() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,'')
            };
            

            this.$API.post( '/api/alteraremail/verificacpf', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 3;
                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Usuário ou senha inválidos';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },  

      gerarCodigo() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,''),
              data_nascimento: this.data_nascimento,
              nome_mae: this.nome_mae,
            };

            this.$API.post( '/api/alteraremail/gerarcodigo', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 4;
                  this.tipo_mensagem = 'info';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;

                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Usuário ou senha inválidos';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },  */



      Confirmar() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,''),
              data_nascimento: this.data_nascimento,
              nome_mae: this.nome_mae,
              email_novo: this.email_novo,
            };

            this.$API.post( '/api/alteraremail/salvar', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 5;
                  this.tipo_mensagem = 'success';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;

                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Não foi possível alterar o e-mail';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },

      



    }
    
     
  }
</script>