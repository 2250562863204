<template>
   <!-- Container principal da tela de login -->
   <v-container 
         fluid
         v-if="mostra_login && !$store.state.usuario.logado"
         class="pa-0 ma-0"
   >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo da tela de login -->
          <v-card
            class="rounded-0"
          >
            <v-img
              min-height="600px"
              max-height="920px"
              src="img/tela_fundo_1.jpg"
            >
              <!-- Dentro da imagem existe um outro container para centralizar o card que contém o formulário de login -->
              <v-container 
                    fluid
                    fill-height

              >
                <!-- Essa v-row centraliza o card do formulário na vertical --> 
                <v-row
                  justify="center"
                >
                  <!-- Para telas pequenas usa toda a largura da tela e para telas medias usa somente sete colunas --> 
                  <v-col cols="12" md="7" >

                    <!-- Esse é o card branco que aparece no meio da tela --> 
                    <v-card
                      class="mx-auto rounded-xl"
                      elevation="12"
                    >
                      <v-row
                        justify="center"
                      >
                        <!-- O card branco tem duas colunas: uma com um card azul e outra com o form de login --> 
                        <v-col cols="12" md="6" class="d-none d-md-block ">
                          <v-container fill-height fluid>

                            <!-- Esse card azul --> 
                            <v-card 
                               dark 
                               class="primary mx-auto rounded-xl " 
                               min-height="360px"    
                               flat
                               
                            >
                              <v-card-title>Consulta Área do Participante</v-card-title>
                              <v-card-text>
                                <br>
                                Interface para consulta à área restrita do participante.
                              </v-card-text>
                          
                            </v-card>
                          </v-container>   
                        </v-col>

                        <!-- Essa é a segunda coluna -->
                        <v-col cols="12" md="6">
                          <v-card-text>

                            <!-- Esse form de login -->
                            <v-form 
                              @submit.prevent="Entrar" 
                              ref="form"
                              v-model="valid"
                              lazy-validation
                            >
                              <v-img
                                  alt="Serpros Fundo Multipatrocinado"
                                  class="shrink"
                                  contain
                                  src="img/serpros-logo.png"
                                  height="60"
                              />

                              <br>

                              <v-text-field
                                  v-model="atendente.nome"
                                  label="Atendente"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                                  readonly
                                  v-if="this.code !=0"
                              ></v-text-field>

                              <v-text-field
                                  v-model="login.cpf"
                                  label="CPF"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                                  v-if="this.code !=0"
                              ></v-text-field>
                              
                              <div class="text-center" v-if="this.code !=0">
                                  <v-btn type="submit"  color="primary" :disabled="!valid" rounded :loading="loading"> Entrar para consulta</v-btn>
                              </div>
                              
                              <br>

                              <v-alert dense v-if="mostra_mensagem" v-model="mostra_mensagem"  text type="error"> {{mensagem}} </v-alert>


                            </v-form>
                          </v-card-text>
                        </v-col>
                      </v-row>    
                    </v-card>
                  </v-col>
                </v-row> 
              </v-container>   
            </v-img>
          </v-card>  
        </v-col>
      </v-row>  
   </v-container>

   
</template>

<script>
  export default {
    data: function(){
      return {  
          active: null,
          indeterminate: null,
          loading: false,
          valid: false,
          Rules:{
            Required:[v => !!v || 'Obrigatório!'],   
          },
          login:{
            cpf:null, 
            senha:null
          },
          mensagem: '',
          mostra_mensagem: false,
          mostra_login: false,
          code: 0,
          usuario: {
                logado: false,
              },
          id_pessoa: null,
          exibe_senha: false,
          atendente: null,
          mensagens: [],
      }
    },

    created: function () {

      this.$store.commit('setatendente', null);
      
      var texto ='<p style="color:white; font-size: 14px;">Digite seu CPF, sua senha e clique em <b>Entrar</b>.</p>'+
                 '<p style="color:white; font-size: 14px;">Caso não tenhalique em <b>Entrar</b>.</p>';

      this.$store.dispatch('exibeajuda',texto);

      this.mensagens[0] = 'Não foi possível gerar um código de acesso';
      this.mensagens[1] = 'Não foi possível gerar uma autorização de acesso';
      this.mensagens[2] = 'Usuário não possui papel para realizar atendimento';
      this.mensagens[3] = 'Atendente não cadastrado';
      this.mensagens[4] = 'Sistema não encontrado';
      this.mensagens[5] = 'Chave não informada';

      if(this.$route.query.code != 0){
        this.code = this.$route.query.code;
        this.Carregar();
      }else{
        this.mensagem = this.mensagens[this.$route.query.msg];
        this.mostra_mensagem = true;
        this.mostra_login = true;
        
      }

      

    },


    methods:{
      
      Carregar(){


        this.loading = true;
        this.axios({
            method: 'post',
            url: this.$store.state.urls.aut+'/api/usuariologado',
            data: {client_id: this.$store.state.credentials.client_id,
                  id_sessao: this.$route.query.aut
                  }
        })
        .then(response => {

          this.atendente = response.data;
          this.$store.commit('setatendente', response.data);
          this.mostra_login = true;
          this.loading = false;

        })
        .catch(error => function() {
              
              console.log(error.response);
              this.loading = false;
              this.encerrasessao();
        });

      },



      Entrar() {

        if( this.$refs.form.validate() ){
          this.mostra_mensagem = false;
          this.loading = true;

          let params = {
            cpf: this.login.cpf.replace(/[^\d]+/g,''), 
            code: this.code 
          }

          this.$API.post( '/api/aut/tokenatendimento', params)
          .then(response => {

            this.$API.Iniciar(response.data.token);
            this.id_pessoa = response.data.id_pessoa;
            this.$store.dispatch('exibeajuda','');
            this.iniciarSessao(); 
                          
          }) 
          .catch(error => {
            console.log(error.response);
            this.mensagem = error.response.data.error_description;
            this.loading = false;
            this.mostra_mensagem = true;
            
          });  

        }

      },


      iniciarSessao(){

        var params = {
            id_pessoa: this.id_pessoa
        }
        
        this.$API.post( '/api/iniciarsessao', params)
        .then(response => {

          if(response.data.codigo == 'ok'){
            
            this.usuario = {
              logado: true,
            };

            
            this.$store.commit('setplanos', response.data.planos);
            this.$store.commit('setusuariologado', this.usuario);
            this.$store.commit('setpessoafisica',response.data.pessoa_fisica);
            this.$store.commit('setsituacao',response.data.situacao);
            this.$store.commit('setalerta','');
            this.$store.commit('setidpessoa',this.id_pessoa);
            this.$store.commit('setidemp',response.data.id_emp);
            this.$store.commit('setplanosusuario', response.data.planos_usuario);
            this.$store.commit('setplanoscontracheque', response.data.planos_contracheque);
            this.$store.commit('setadimplente_rs', response.data.adimplente_rs);
            this.$store.commit('setin1343', response.data.tem_in1343);
            this.$store.commit('setrecad', response.data.recad);
            this.$store.commit('setrecadanalise', response.data.recad_analise);
          
            this.carregaTabelas();

          }else{
            this.loading = false;
            this.mostra_login = true;
          }
            
        }) 
        .catch(error => {
            console.log(error.response.data);
            this.loading = false;
            this.mostra_login = true;
        });  
      },


      carregaTabelas(){

        this.$API.get('/api/tabelas')
        .then(response => {
             
            
            this.$store.commit('setbeneficios', response.data.beneficios);
            this.$store.commit('setbancosemprestimo', response.data.bancos);
            this.$store.dispatch('iniciamenu', response.data.menu);

            let params = {
               api: this.$API,
               descricao: 'O atendente entrou'
            }

            this.$store.dispatch('logatendimento', params); 
            
            this.mostra_login = false; 
            this.$router.push('/');
            
        });
      },

    }
    
     
  }
</script>