import Vue from 'vue'

import Router from './routes.js'
import Store from './store.js'
import Request from './api.js'
import APIpartic from './apipartic.js'
Vue.prototype.$API = Request
Vue.prototype.$APIpartic = APIpartic


import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/custom.scss'

__webpack_nonce__ = "MDMwZDkwZTUxY2Y3ZWQ0ZjVmNmQ3OGQwZjRlY2M0NjJhZWFiM2E0Y2JmZDE1OTQzMTJkYzZhNTc2NjQxN2VkMw=="
Vue.config.productionTip = false


new Vue({
  router: Router,
  store: Store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
