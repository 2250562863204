import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
  state: {
    usuario: {
      logado: false
    },
    urls: {
      self: process.env.VUE_APP_URL,
      ged: process.env.VUE_APP_APIGED,
      aut: process.env.VUE_APP_AUT,
    },
    credentials: {
      client_id: process.env.VUE_APP_CLIENTID
    },
    id_sistema: process.env.VUE_APP_IDSISTEMA,
    pessoa_fisica: null,
    id_pessoa_fisica: null, //para manter compatibilidade com sistema de requerimentos
    ajuda: '',
    nuvem: null,
    bancos_emprestimo: [],
    planos: null,
    plano_participante: [],
    planos_usuario: [],
    planos_contracheque: [],
    index_plano_selecionado: 0,
    beneficiario_recebedor: [],
    beneficios: null,
    situacoes: null,
    tipos_documento: null,
    situacao: null,
    sidebar: true,
    tipos_req: null,
    docs_obrigatorios: null,
    id_pessoa: null,
    id_emp: null,
    hist_plano: [],
    versao: process.env.VUE_APP_VERSAO,
    permite_reforma: process.env.VUE_APP_REFORMA,
    bancos: [
      {numero:"001", nome: "BANCO DO BRASIL"},
      {numero:"104", nome: "CAIXA ECONÔMICA FEDERAL"},
      {numero:"237", nome: "BRADESCO"},
      {numero:"033", nome: "SANTANDER"}
    ],
    tipos_pagamento: [
      {id:1, descricao: "Crédito em conta corrente"},
      {id:2, descricao: "Crédito em conta salário"},
    ],
    alerta: '',
    menu: [],
    atendente: null,
    taxa_emprestimo: 0,
    adimplente_rs: false,
    tem_in1343: false,
    max_file_size: 5,
    recad: 1,
    recad_analise: 'N',
    ciente_recad: true
  },
  mutations: {
    switchSidebar(state){
      state.sidebar = !state.sidebar;
    },
    setusuariologado(state, p1){
      state.usuario = p1;
    },
    setatendente(state, p1){
      state.atendente = p1;
    },
    setajuda(state, p1){
      state.ajuda = p1; 
    },
    setidpessoa(state, p1){
      state.id_pessoa = p1; 
    },
    setidemp(state, p1){
      state.id_emp = p1; 
    },
    sethistplano(state, p1){
      state.hist_plano = p1; 
    },
    setpessoafisica(state, p1){
      state.pessoa_fisica = p1; 
    },
    setbeneficiariorecebedor(state, p1){
      state.beneficiario_recebedor = p1; 
    },
    setnuvem(state, p1){
      state.nuvem = p1; 
    },
    setplanos(state, p1){
      state.planos = p1; 
    },
    setbeneficios(state, p1){
      state.beneficios = p1; 
    },
    setsituacoes(state, p1){
      state.situacoes = p1; 
    },
    settipos_documento(state, p1){
      state.tipos_documento = p1; 
      
    },
    settiposreq(state, p1){
      state.tipos_req = p1; 
    },
    setbancosemprestimo(state, p1){
      state.bancos_emprestimo = p1; 
    },
    settaxaemprestimo(state, p1){
      state.taxa_emprestimo = p1; 
    },
    setdocsobrigatorios(state, p1){
      state.docs_obrigatorios = p1; 

      for (let i = 0; i < state.docs_obrigatorios.length; i++) {
        state.docs_obrigatorios[i].id_tipo_documento = parseInt(state.docs_obrigatorios[i].id_tipo_documento);
        state.docs_obrigatorios[i].id_tipo_requerimento = parseInt(state.docs_obrigatorios[i].id_tipo_requerimento);
      }
    },
    setplanoparticipante(state, p1){
      state.plano_participante = p1; 
    },
    setsituacao(state, p1){
      state.situacao = p1; 
    },
    setplanoselecionado(state, p1){
      state.index_plano_selecionado = p1; 
    },
    setalerta(state, p1){
      state.alerta = p1; 
    },
    setplanosusuario(state, p1){
      state.planos_usuario = [];
      state.planos_usuario = p1; 
    },
    setplanoscontracheque(state, p1){
      state.planos_contracheque = [];
      state.planos_contracheque = p1; 
    },
    setadimplente_rs(state, p1){
      state.adimplente_rs = p1;
    },
    setin1343(state, p1){
      state.tem_in1343 = p1;
    },
    setmenu(state, p1){
      state.menu = [];
      state.menu = p1; 
    },
    setrecad(state, p1){
      state.recad = p1; 
    },
    setrecadanalise(state, p1){
      state.recad_analise = p1; 
    },
    setcienterecad(state, p1){
      state.ciente_recad = p1; 
    },
  },

  actions: {
    
    exibeajuda(context,ajuda){
      context.commit('setajuda',ajuda);
    },

    iniciamenu(context, menu_completo){

      //console.log(this.state.planos_usuario);
      let ativo = false;
      let ps1_ativo = false;
      let ps2_ativo = false;
      let assistido = false;
      let ps1_assistido = false;
      let ps2_assistido = false;
      let ps1 = false;
      let ps2 = false;
      let desligado_ps1 = false;
      let desligado_ps2 = false;
      let ja_resgatou_ps1 = false;
      let ja_resgatou_ps2 = false;
      let mostra_boleto = false;
      //let exibe_recad = false;
      

      let len = this.state.planos_usuario.length;     
      for(let i = 0; i<len; i++){

          if(this.state.planos_usuario[i].id_pessoa == 1012178 ||
            this.state.planos_usuario[i].id_pessoa  == 103049  ||
            this.state.planos_usuario[i].id_pessoa  == 10049   ||
            this.state.planos_usuario[i].id_pessoa  == 106861  ||
            this.state.planos_usuario[i].id_pessoa  == 103635
          ){
            mostra_boleto = true;
          }

          //if(this.state.planos_usuario[i].id_pessoa == 103635){
          //  exibe_recad = true;
          //}

          if(this.state.planos_usuario[i].id_plano == 1){
            ps1 = true;
            if(this.state.planos_usuario[i].ja_resgatou){
              ja_resgatou_ps1 = true;
            }
          }

          if(this.state.planos_usuario[i].id_plano == 2){
            ps2 = true;
            if(this.state.planos_usuario[i].ja_resgatou){
              ja_resgatou_ps2 = true;
            }
          }

          if( this.state.planos_usuario[i].situacao =="ATIVO" ||
              this.state.planos_usuario[i].situacao =="BPD" || 
              this.state.planos_usuario[i].situacao =="SALDADO" || 
              this.state.planos_usuario[i].situacao =="AUTOPATROCÍNIO" ||
              this.state.planos_usuario[i].situacao =="AUXÍLIO DOENÇA" ||
              this.state.planos_usuario[i].situacao =="ACIDENTE DE TRABALHO"){
              
              ativo = true;

              if(this.state.planos_usuario[i].id_plano == 1){
                ps1_ativo = true;
              }
    
              if(this.state.planos_usuario[i].id_plano == 2){
                ps2_ativo = true;
              }

              
          }

          if( this.state.planos_usuario[i].situacao =="ASSISTIDO" ||
              this.state.planos_usuario[i].situacao =="PENSIONISTA" ||
              this.state.planos_usuario[i].situacao =="AUXÍLIO DOENÇA" ||
              this.state.planos_usuario[i].situacao =="ACIDENTE DE TRABALHO"){
          
              assistido = true;

              if(this.state.planos_usuario[i].id_plano == 1){
                ps1_assistido = true;
              }
    
              if(this.state.planos_usuario[i].id_plano == 2){
                ps2_assistido = true;
              }
          }

          if( this.state.planos_usuario[i].situacao =="DESLIGADO"){

              if(this.state.planos_usuario[i].id_plano == 1){
                desligado_ps1 = true;
              }
    
              if(this.state.planos_usuario[i].id_plano == 2){
                desligado_ps2 = true;
              }
          }

      }

      //console.log(exibe_recad);


       /* Regras do menu:

        1 - Todos podes acessar
        2 - Somente ativos do PS1
        3 - Somente ativos de qualquer plano
        4 - Somente assistidos de qualquer plano
        5 - Somente PS2, qualquer situação
        6 - Somente ativos do PS2 
        7 - Ativos e Assistidos
        8 - Ativos e desligados de qualquer plano
        9 - Pessoas do RS que estavam adimplentes em 30/04/2024
        10 - Pessoas do RS que estavam adimplentes em 30/04/2024
        11 - Contribuição extraordinária
        12 - Recadastramento
        */ 

      //console.log(menu_completo);
    
      let menu = [];

      let pode_exibir = false;
      len = menu_completo.length;
      for(let i=0; i<len; i++) {

        
        if(menu_completo[i].id_pai == null){
          menu.push({ text: menu_completo[i].descricao, icon: 'mdi-chevron-up', 'icon-alt': menu_completo[i].icone, model: false, children: []});          

        }else{

          

          pode_exibir = false;
  
          //1 - Todos podem acessar
          if( menu_completo[i].regra_permissao == 1){
            pode_exibir = true;
          }
          //2 - Somente ativos do PS1
          if( menu_completo[i].regra_permissao == 2 && ativo && ps1_ativo){
            pode_exibir = true;
          }
          //3 - Somente ativos de qualquer plano
          if( menu_completo[i].regra_permissao == 3 && ativo){
            pode_exibir = true;
          }
          //4 - Somente assistidos de qualquer plano
          if( menu_completo[i].regra_permissao == 4 && this.state.planos_contracheque.length > 0){
            pode_exibir = true;
          }
          //5 - Somente PS2, qualquer situação
          if( menu_completo[i].regra_permissao == 5 && ps2 && !desligado_ps2){
            pode_exibir = true;
          }
          //6 - Somente ativos do PS2
          if( menu_completo[i].regra_permissao == 6 && ativo && ps2_ativo){
            pode_exibir = true;
          }
          //7 - Todos exceto os desligados
          if( menu_completo[i].regra_permissao == 7 && (ativo || assistido)){
            pode_exibir = true;
          }
          //8 - Ativos e desligados de qualquer plano
          if( menu_completo[i].regra_permissao == 8 && (ativo || ( (desligado_ps1 && !ja_resgatou_ps1) || (desligado_ps2 && !ja_resgatou_ps2)))){
            pode_exibir = true;
          }

          //9 - Pessoas do RS que estavam adimplentes em 30/04/2024
          const currentDate = new Date();
          const day = currentDate.getDate();
          const month = currentDate.getMonth() + 1; // Add 1 as months are zero-based
          const year = currentDate.getFullYear();
          var mes = month.toString();
          var dia = day.toString();

          
          if( menu_completo[i].regra_permissao == 9 && this.state.adimplente_rs && year+'-'+mes.padStart(2, '0')+'-'+dia.padStart(2, '0') <= '2024-06-07'){
            pode_exibir = true;
          }

          //10 - Todos exceto os desligados desde que possuam IN 1343 
          if( menu_completo[i].regra_permissao == 10 && (ativo || assistido) && this.state.tem_in1343){
            pode_exibir = true;
          }

          //11 - só algumas pessoas
          if( menu_completo[i].regra_permissao == 11 && mostra_boleto){
            pode_exibir = true;
          }

          // Recadastramento
          //if( menu_completo[i].regra_permissao == 12 && exibe_recad){
            //console.log('recad');
            //pode_exibir = true;
          //}

          //Se na tabela estiver marcado N não exibe o menu
          if( menu_completo[i].ativo =="N"){
            pode_exibir = false;
          }
          
          if(pode_exibir){
            menu[menu.length-1].children.push({ text: menu_completo[i].descricao,  tipo: menu_completo[i].tipo, path:menu_completo[i].path });
          }
          
        }
      }

      len = menu.length;
      for(let i=0; i<len; i++) {

        if(menu[i].id_pai == null){
          if(menu[i].children.length == 0){
            menu.splice(i,1);
            len = menu.length;
            i--;
          }
        }

      }

      


      this.commit('setmenu', menu);


    },

    logatendimento(context, params){

      if(context.state.atendente != null){

        let data = {
          cpf_participante: context.state.pessoa_fisica.cpf, 
          id_usuario: context.state.atendente.id,
          operacao: params.descricao,
        }

        params.api.post( '/api/atendimento/log', data)
      }

    }

    
  },


})

export default store